<template>
    <div class="cooperationContainer">
        <div class="top" :style="{ backgroundImage: 'url(' + topImage + ')' }">
            <div class="topLeft">
                <div class="topTitle" key="1">
                    {{ topTitle }}
                </div>
                <div class="topContent" key="2">
                    {{ topContent }}
                </div>
                <div class="topButton" key="3" @click="isShowCommitP = true">
                    合作咨询
                </div>
            </div>
        </div>
        <div class="middle">
            <div class="middleTitle">
                <div class="arrowLeft"></div>
                <div class="titleText">
                    为什么要成为海行云合作伙伴
                </div>
                <div class="arrowRight"></div>
            </div>
            <transition-group class="middleContent" name="fade">
                <div class="contentItem" v-for="item in middleItems" :key="item.key">
                    <div class="itemImage">
                        <img :src="imgurl(item.imageUrl)" alt="">
                    </div>
                    <div class="itemTitle">
                        {{ item.title }}
                    </div>
                    <div class="itemContent">
                        {{ item.content }}
                    </div>
                </div>
            </transition-group>
        </div>
        <div class="bottom">
            <div class="bottomTitle">
                <div class="arrowLeft"></div>
                <div class="titleText">
                    伙伴类型
                </div>
                <div class="arrowRight"></div>
            </div>
            <transition-group class="bottomContent" name="fade">
                <div class="contentItem" v-for="item in bottomItems" :key="item.key">
                    <img :src="imgurl(item.imageUrl)" alt="">
                    <div class="itemTitle">
                        {{ item.title }}
                    </div>
                    <div class="itemContent">
                        {{ item.content }}
                    </div>
                </div>
            </transition-group>
        </div>
        <!-- 留言弹窗 -->
        <message-form class="common-message-form" v-model="isShowCommitP" :mask="true" @close="isShowCommitP = false"></message-form>
    </div>
</template>
<script>
import MessageForm from '@/components/MessageForm'
import api from '@/api/index.js'
const { VUE_APP_API } = process.env
export default {
    components: { MessageForm },
    data() {
        return {
            isShowCommitP: false,
            topImage: '',
            topTitle: '',
            topContent: '',
            middleItems: [],
            bottomItems: []
        }
    },
    methods: {
        imgurl(path) {
            return `${VUE_APP_API}${path}`.replace('/api', '')
        },
        async init() {
            //获取栏目信息
            // const channelId = (await api.getChannelsList()).filter(channel => channel.channelName === '生态合作')[0].id
            const channelId = 13
            const channel = await api.getChannel({ channelId })
            this.topImage = channel.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', '')
            //获取栏目下的文章
            const contents = (await api.getContentsList({ channelId })).contents
            const topContent = contents[contents.length - 1]
            this.topTitle = topContent.title
            this.topContent = topContent.body.replace(/<[^<>]+>/g, "");
            //获取栏目下的子栏目的文章
            const middleItem1 = contents[contents.length - 2]
            const middleItem2 = contents[contents.length - 3]
            const middleItem3 = contents[contents.length - 4]
            this.middleItems = [
                {
                    key: 1,
                    imageUrl: middleItem1.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: middleItem1.title,
                    content: middleItem1.body.replace(/<[^<>]+>/g, "")
                },
                {
                    key: 2,
                    imageUrl: middleItem2.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: middleItem2.title,
                    content: middleItem2.body.replace(/<[^<>]+>/g, "")
                },
                {
                    key: 3,
                    imageUrl: middleItem3.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: middleItem3.title,
                    content: middleItem3.body.replace(/<[^<>]+>/g, "")
                }
            ]
            const bottomItem1 = contents[contents.length - 5]
            const bottomItem2 = contents[contents.length - 6]
            const bottomItem3 = contents[contents.length - 7]
            this.bottomItems = [
                {
                    key: 1,
                    imageUrl: bottomItem1.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: bottomItem1.title,
                    content: bottomItem1.body.replace(/<[^<>]+>/g, "")
                },
                {
                    key: 2,
                    imageUrl: bottomItem2.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: bottomItem2.title,
                    content: bottomItem2.body.replace(/<[^<>]+>/g, "")
                },
                {
                    key: 3,
                    imageUrl: bottomItem3.imageUrl?.replace('@', VUE_APP_API)?.replace('/api', ''),
                    title: bottomItem3.title,
                    content: bottomItem3.body.replace(/<[^<>]+>/g, "")
                }
            ]
        },
    },
    mounted() {
        this.init()
    },
}
</script>
<style lang="less" scoped>
.cooperationContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .top {
        width: 1920px;
        height: 520px;
        padding-left: 360px;
        padding-top: 166px;
        .topLeft {
            display: flex;
            flex-direction: column;
            height: 240px;
        }

        .topTitle {
            font-family: PingFangSC-Semibold;
            font-size: 38px;
            font-weight: 700;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #303d42;
        }

        .topContent {
            width: 530px;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 32px;
            letter-spacing: 0px;
            color: #303d42;
            margin: 25px 0;
        }

        .topButton {
            width: 140px;
            height: 48px;
            background-color: #044e9f;
            border-radius: 24px;
            line-height: 48px;
            text-align: center;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #ffffff;
        }
    }

    .middle {
        width: 1920px;
        height: 520px;
        padding-top: 71px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .middleTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;

            .titleText {
                font-family: PingFangSC-Semibold;
                font-size: 36px;
                font-weight: 700;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #333333;
                margin: 0 25px;
            }

            .arrowLeft {
                width: 80px;
                height: 2px;
                background-image: url('~@/assets/cooperation/左.png');
            }

            .arrowRight {
                width: 80px;
                height: 2px;
                background-image: url('~@/assets/cooperation/右.png');
            }
        }

        .middleContent {
            display: flex;
            justify-content: center;
            &>.contentItem:not(:last-child){ margin-right: 160px; }
            .contentItem {
                width: 310px;
                display: flex;
                flex-direction: column;
                align-items: center;

                .itemTitle {
                    font-family: PingFangSC-Semibold;
                    font-size: 22px;
                    font-weight: 700;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #333333;
                    margin: 46px 0 22px 0;
                }

                .itemContent {
                    width: 310px;
                    font-family: PingFangSC-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 26px;
                    letter-spacing: 0px;
                    color: #666666;
                    text-align: center;
                }
            }
        }
    }

    .bottom {
        width: 1920px;
        height: 680px;
        background-color: #f3f6fa;
        opacity: 0.9;
        padding-top: 63px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .bottomTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;

            .titleText {
                font-family: PingFangSC-Semibold;
                font-size: 36px;
                font-weight: 700;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #333333;
                margin: 0 25px;
            }

            .arrowLeft {
                width: 80px;
                height: 2px;
                background-image: url('~@/assets/cooperation/左.png');
            }

            .arrowRight {
                width: 80px;
                height: 2px;
                background-image: url('~@/assets/cooperation/右.png');
            }
        }

        .bottomContent {
            display: flex;
            justify-content: center;
            &>.contentItem:not(:last-child) { margin-right: 25px; }
            .contentItem {
                width: 384px;
                height: 400px;
                background-color: #ffffff;
                padding: 10px;
                box-shadow: 0 0 10px rgba(204, 204, 204, 0.5);

                .itemTitle {
                    font-family: PingFangSC-Semibold;
                    font-size: 22px;
                    font-weight: 700;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #333333;
                    margin: 30px 0 15px 0;
                    padding: 0 16px;
                }

                img {
                    width: 364px;
                    height: 214px;
                    background-color: #d7d7d7;
                }

                .itemContent {
                    padding: 0 16px;
                    font-family: PingFangSC-Regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 26px;
                    letter-spacing: 0px;
                    color: #666666;
                }
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: all .5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

}
</style>